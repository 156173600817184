/* eslint-disable no-undef */
import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

import { Layout, Popover, Menu } from 'antd';

// images
import Logo from 'assets/images/logo.svg';
import ChatIcon from 'assets/icons/chat.svg';
import BellIcon from 'assets/icons/bell.svg';
import QuestionIcon from 'assets/icons/question.svg';
import UserIcon from 'assets/icons/user.svg';

const { Header } = Layout;

const HeaderPage = () => {
  const handleLogout = () => {
    localStorage.removeItem('italic_token');
    window.location.href = '/auth';
  };

  return (
    <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
      <Link to="/" className="logo">
        <img alt="" src={Logo} />
      </Link>

      <div className="mainMenus">
        <Link to="/">
          <img alt="" src={ChatIcon} /> Feedback?
        </Link>
        <Link to="/">
          <img alt="" src={BellIcon} />
        </Link>
        <Link to="/">
          <img alt="" src={QuestionIcon} />
        </Link>
        <Popover
          placement="bottomRight"
          trigger="click"
          content={
            <Menu className="userMenus">
              <Menu.Item>Profile</Menu.Item>
              <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
            </Menu>
          }>
          <img alt="" src={UserIcon} />
        </Popover>

        {/* <Link to="#"> */}

        {/* </Link> */}
      </div>
    </Header>
  );
};

export default HeaderPage;
