import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api/';

const token = localStorage.getItem('italic_token') || null;

const Axios = axios.create({
  baseURL,
  headers: {
    Authorization: token ? `Bearer ${token}` : null,
    'Content-Type': 'application/json;charset=utf-8'
  }
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("err: ", error);
    if (error.response.status === 401) {
      //place your reentry code
      window.location.href = '/auth';
    }
    return error;
  }
);

export default Axios;
