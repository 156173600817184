import Axios from 'config/axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const createIntent = createAsyncThunk('invoices/create-intent', async (intent) => {
  const response = await Axios.post('invoices/create-intent', intent);
  return response.data;
});

export const getMyInvoices = createAsyncThunk('invoices/list', async () => {
  const response = await Axios.get('invoices');
  return response.data;
});

export const createInvoice = createAsyncThunk('invoices/create', async (invoice) => {
  const response = await Axios.post('invoices/create', invoice);
  return response.data;
});

export const getInvoiceWithId = createAsyncThunk('invoices/detail', async (id) => {
  const response = await Axios.get(`invoices/${id}`);
  return response.data;
});

export const updateStatusInvoice = createAsyncThunk(
  'invoices/update-status',
  async ({ invoiceId, status }) => {
    const response = await Axios.put(`invoices/${invoiceId}/status`, { status });
    return response.data;
  }
);

const initialState = {
  loading: false,
  errors: null,
  invoices: [],
  invoice: null,
  isCreated: false,
  client_secret: null,
  isUpdatedStatus: false
};

const invoicesSlice = createSlice({
  name: 'invoices/list',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.isCreated = false;
      state.errors = null;
      state.isUpdatedStatus = false;
    }
  },
  extraReducers: (builder) => {
    // create intent
    builder.addCase(createIntent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createIntent.fulfilled, (state, action) => {
      state.loading = false;
      state.client_secret = action.payload.client_secret;
    });
    builder.addCase(createIntent.rejected, (state, action) => {
      state.loading = false;
      state.client_secret = null;
      state.errors = {
        create_intent: action.error
      };
    });
    // my invoices
    builder.addCase(getMyInvoices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.invoices = action.payload;
    });
    builder.addCase(getMyInvoices.rejected, (state, action) => {
      state.loading = false;
      state.invoices = [];
      state.errors = {
        invoices: action.error
      };
    });
    // create invoice
    builder.addCase(createInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
      state.isCreated = action.payload ? true : false;
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.loading = false;
      state.invoice = null;
      state.isCreated = action.payload ? true : false;
      state.errors = {
        create: action.error
      };
    });
    // get invoice detail
    builder.addCase(getInvoiceWithId.pending, (state) => {
      state.loading = false;
      state.errors = null;
    });
    builder.addCase(getInvoiceWithId.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    });
    builder.addCase(getInvoiceWithId.rejected, (state, action) => {
      state.loading = false;
      state.invoice = null;
      state.errors = {
        detail: action.error
      };
    });
    // update status invoice
    builder.addCase(updateStatusInvoice.pending, (state) => {
      state.loading = false;
      state.errors = null;
    });
    builder.addCase(updateStatusInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.isUpdatedStatus = action.payload ? true : false;
    });
    builder.addCase(updateStatusInvoice.rejected, (state, action) => {
      state.loading = false;
      state.isUpdatedStatus = false;
      state.errors = {
        status: action.error
      };
    });
  }
});

export const { resetState } = invoicesSlice.actions;

export default invoicesSlice.reducer;
