import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import { routes } from 'routes';
import { Spin, Layout } from 'antd';

import Header from 'components/layouts/header';

const { Content } = Layout;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Spin size="large" />}>
        <BrowserRouter>
          <Layout className="wrapper">
            <Header />

            <Content className="contentWrapper">
              <Switch>
                {routes.map((route, i) => {
                  return (
                    <Route
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      render={(props) => <route.component {...props} routes={route.routes} />}
                    />
                  );
                })}
              </Switch>
            </Content>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
