import Axios from 'config/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const createAccountLink = createAsyncThunk('transfer/account-link', async () => {
  const response = await Axios.post('auth/account-link');
  return response.data;
});

const initialState = {
  loading: false,
  errors: null,
  account_link: null
};

const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAccountLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAccountLink.fulfilled, (state, action) => {
      state.loading = false;
      state.account_link = action.payload.url;
    });
    builder.addCase(createAccountLink.rejected, (state, action) => {
      state.loading = false;
      state.account_link = null;
      state.errors = {
        account_link: action.error
      };
    });
  }
});

export default transferSlice.reducer;
