import { configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import invoices from './invoices';
import transfer from './transfer';

const store = configureStore({
  reducer: {
    auth,
    invoices,
    transfer
  }
});

export default store;
