import { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard"));
const Auth = lazy(() => import("pages/auth"));
const ForgotPassword = lazy(() => import("pages/auth/forgot-password"));
const NewPassword = lazy(() => import("pages/auth/new-password"));
const Return = lazy(() => import("pages/auth/return"));
const Refresh = lazy(() => import("pages/auth/refresh"));
const AuthWelcome = lazy(() => import("pages/auth/welcome"));
const AuthVerify = lazy(() => import("pages/auth/verify"));
const Invoice = lazy(() => import("pages/invoice"));
const InvoiceDetail = lazy(() => import("pages/invoice-detail"));
const Payment = lazy(() => import("pages/payment"));
const FormCard = lazy(() => import("pages/payment/form-card"));
const Transfer = lazy(() => import("pages/transfer"));
const SendPaymentLink = lazy(() => import("pages/send-payment-link"));
const TransferHistory = lazy(() => import("pages/transfer-history"));
const PaymentStatus = lazy(() => import("pages/payment/payment-status"));

export const routes = [
  {
    path: "/auth/return",
    component: Return,
    exact: true,
  },
  {
    path: "/auth/refresh",
    component: Refresh,
    exact: true,
  },
  {
    path: "/auth/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
  {
    path: "/auth/new-password",
    component: NewPassword,
    exact: true,
  },
  {
    path: "/auth",
    component: Auth,
    exact: true,
  },
  {
    path: "/send-payment-link",
    component: SendPaymentLink,
    exact: true,
  },
  {
    path: "/welcome",
    component: AuthWelcome,
    exact: true,
  },
  {
    path: "/verify",
    component: AuthVerify,
    exact: true,
  },
  {
    path: "/invoice/:invoiceId",
    component: InvoiceDetail,
    exact: true,
  },
  {
    path: "/payment/:invoiceId/status",
    component: PaymentStatus,
    exact: true,
  },
  {
    path: "/payment/:invoiceId/checkout",
    component: FormCard,
    exact: true,
  },
  {
    path: "/payment/:invoiceId",
    component: Payment,
    exact: true,
  },
  {
    path: "/invoice",
    component: Invoice,
    exact: true,
  },
  {
    path: "/transfer",
    component: Transfer,
    exact: true,
  },
  {
    path: "/transfer-history",
    component: TransferHistory,
    exact: true,
  },
  {
    path: "/",
    component: Dashboard,
    exact: true,
  },
];
