import Axios from "config/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const register = createAsyncThunk("auth/register", async (user) => {
  const response = await Axios.post("auth/register", user);
  return response.data;
});

export const login = createAsyncThunk(
  "auth/login",
  async (user, { rejectWithValue }) => {
    try {
      const response = await Axios.post("auth/login", user);
      console.log(">> response: ", response);
      if (response.data) {
        return response.data;
      } else if (response.response) {
        console.log(">> xxx: ", response.response.data);
        return rejectWithValue(response.response.data);
      }
      return "";
    } catch (error) {
      console.log("Error: ", error);
      return error.response ? error.response.data.message : error.message;
      // return rejectWithValue(error.response.data);
    }
  }
);

export const verifyCompleted = createAsyncThunk(
  "auth/verify",
  async (accountId) => {
    const response = await Axios.post("auth/verify", { accountId });
    return response.data;
  }
);

export const getProfile = createAsyncThunk("auth/profile", async () => {
  const response = await Axios.get("auth/profile");
  return response.data;
});

export const getBalance = createAsyncThunk("auth/balance", async () => {
  const response = await Axios.get("auth/balance");
  return response.data;
});

export const refreshAccountLink = createAsyncThunk(
  "auth/refresh-link",
  async (accountId) => {
    console.log("accountId: ", accountId);
    const response = await Axios.post("auth/refresh-link", { accountId });
    return response.data;
  }
);

export const sendEmailReset = createAsyncThunk(
  "auth/email-reset-password",
  async (email) => {
    const response = await Axios.post("auth/reset-password/send-mail", {
      email,
    });
    return response.data;
  }
);

export const updateNewPassword = createAsyncThunk(
  "auth/email-update-password",
  async (update) => {
    const response = await Axios.post(
      "auth/reset-password/update-password",
      update
    );
    return response.data;
  }
);

const initialState = {
  errors: null,
  loading: false,
  isLogin: false,
  token: null,
  isRegister: false,
  isCompleted: false,
  user: null,
  balance: null,
  link_redirect: null,
  isRefresh: false,
  isSentEmail: false,
  isUpdatedPassword: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLogin = false;
      state.isRegister = false;
      state.isCompleted = false;
      state.isRefresh = false;
      state.isSentEmail = false;
      state.isUpdatedPassword = false;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    // send email update password
    builder.addCase(updateNewPassword.pending, (state) => {
      state.loading = true;
      state.isUpdatedPassword = false;
      state.errors = null;
    });
    builder.addCase(updateNewPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.isUpdatedPassword = action.payload ? true : false;
    });
    builder.addCase(updateNewPassword.rejected, (state, action) => {
      state.loading = false;
      state.isUpdatedPassword = false;
      state.errors = {
        updateNewPassword: action.error,
      };
    });
    // send email reset password
    builder.addCase(sendEmailReset.pending, (state) => {
      state.loading = true;
      state.isSentEmail = false;
      state.errors = null;
    });
    builder.addCase(sendEmailReset.fulfilled, (state, action) => {
      state.loading = false;
      state.isSentEmail = action.payload ? true : false;
    });
    builder.addCase(sendEmailReset.rejected, (state, action) => {
      state.loading = false;
      state.isSentEmail = false;
      state.errors = {
        sendMail: action.error,
      };
    });
    // register
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.isRegister = false;
      state.errors = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      state.isRegister = action.payload ? true : false;
      state.token = action.payload //&& action.payload.token || null;
      state.user = action.payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.isRegister = false;
      state.token = null;
      state.errors = {
        register: action.error,
      };
    });
    // login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.isLogin = false;
      state.errors = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.link) {
        state.link_redirect = action.payload.link;
      } else {
        state.isLogin = action.payload ? true : false;
        state.token = action.payload.token;
        state.user = action.payload;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log(">>> action: ", action);
      state.loading = false;
      state.isLogin = false;
      state.token = null;
      state.errors = {
        login: action.payload || action.error,
      };
    });
    // verify
    builder.addCase(verifyCompleted.pending, (state) => {
      state.loading = true;
      state.isCompleted = false;
      state.errors = null;
    });
    builder.addCase(verifyCompleted.fulfilled, (state, action) => {
      state.loading = false;
      state.isCompleted = action.payload ? true : false;
      state.token = action.payload.token;
    });
    builder.addCase(verifyCompleted.rejected, (state, action) => {
      state.loading = false;
      state.isCompleted = false;
      state.errors = {
        verify: action.error,
      };
    });
    // profile
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      state.errors = {
        profile: action.error,
      };
    });
    // balance
    builder.addCase(getBalance.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.balance = action.payload;
    });
    builder.addCase(getBalance.rejected, (state, action) => {
      state.loading = false;
      state.errors = {
        balance: action.error,
      };
    });
    // refresh link
    builder.addCase(refreshAccountLink.pending, (state) => {
      state.loading = true;
      state.isRefresh = false;
      state.link_redirect = null;
    });
    builder.addCase(refreshAccountLink.fulfilled, (state, action) => {
      state.loading = false;
      state.isRefresh = true;
      state.link_redirect = action.payload.link;
    });
    builder.addCase(refreshAccountLink.rejected, (state, action) => {
      state.loading = false;
      state.isRefresh = false;
      state.link_redirect = null;
      state.errors = {
        refresh: action.error,
      };
    });
  },
});

export const { resetState } = authSlice.actions;

export default authSlice.reducer;
